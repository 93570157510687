import React, {useEffect, useState} from 'react';
import './App.css';
import {Route, Routes, useNavigate, Navigate} from "react-router-dom";
import Horse from "./components/horse/Horse";
import axios from "axios";
import Horses from "./components/horses/Horses";
import Welcome from "./components/welcome/Welcome";
import RegisterForm from "./components/authentication/RegisterForm";
import NavBar from "./components/navigation/NavBar";
import LoginForm from "./components/authentication/LoginForm";
import {ThemeProvider} from "@mui/material/styles";
import {CookiesProvider, useCookies} from "react-cookie";
import Landing from "./components/landing/Landing";
import Ranch from "./components/ranch/Ranch";
import Sales from "./components/sales/Sales";
import Shows from "./components/shows/Shows";
import Show from "./components/shows/Show";
import Rollover from "./components/Rollover";
import Messages from "./components/account/messages/Messages";
import {Base64} from 'js-base64';
import Studs from "./components/studs/Studs";
import AccountManagement from "./components/account/AccountManagement";
import {Button, CssBaseline, Dialog, DialogActions, DialogContentText, DialogTitle} from "@mui/material";
import ForgotPassword from "./components/authentication/ForgotPassword";
import ResetPassword from "./components/authentication/ResetPassword";
import Confirmation from "./components/authentication/Confirmation";
import Footer from "./components/Footer";
import TermsOfService from "./components/TermsOfService";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ContactUs from "./components/ContactUs";
import Forum from "./components/forum/Forum";
import SubCategory from "./components/forum/SubCategory";
import ReactGA from 'react-ga4';
import NotFound from "./components/NotFound";
import Rankings from "./components/rankings/Rankings";
import Search from "./components/searches/Search";
import {darkTheme, lightTheme} from "./Theme";
import GameRules from "./components/GameRules";
import NewPlayerPopUp from "./components/tutorial/NewPlayerPopUp";
import {Chat} from "@mui/icons-material";
import GlobalChat from "./components/chat/GlobalChat";
import MessageThread from "./components/account/messages/MessageThread";
import {dark} from "@mui/material/styles/createPalette";
import Clubs from "./components/clubs/Clubs";
import Club from "./components/clubs/Club";
import Post from "./components/forum/Post";

const TRACKING_ID = "G-W854P8L7J1";
ReactGA.initialize(TRACKING_ID);

function App() {
    const history = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(["jwt"]);
    const token = 'Bearer ' + cookies.jwt;
    const api = process.env.REACT_APP_API_KEY;
    const [isTest, setIsTest] = useState(false);

    const config = {
        headers: {
            'Content-Type': 'application/json-patch+json',
            'Authorization': token,
            "Authentication": api
        }
    }

    const formConfig = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': token,
            "Authentication": api,
        }
    }

    useEffect(() => {
        const now = new Date();
        const targetHour = 12; // Replace with the target hour (24-hour format)

        if (now.getHours() === targetHour) {
            // Perform API call at the specific time
        }
    }, []);

    const [user, setUser] = useState();
    const [email, setEmail] = useState();

    const [isLogged, setIsLogged] = useState(cookies.jwt != null);
    const [newPlayerOpen, setNewPlayerOpen] = useState(false);
    
    const openNewPlayer = () => {
        setNewPlayerOpen(true);
    }
    
    const closeNewPlayer = () => {
        setNewPlayerOpen(false);
        axios.post("api/Account/loggedOnce", JSON.stringify(user.email), config)
            .then(response => {
                console.log(response);
            }).catch(error => {
                console.log(error);
        })
    }

    const [darkMode, setDarkMode] = useState(false);

    const toggleDarkMode = (event) => {
        setDarkMode(event.target.checked);

        const data = new FormData();
        data.append("email", user.email);
        data.append("toggle", event.target.checked);

        axios.post("api/Account/toggleDarkMode", data, formConfig)
            .then(response => {
                console.log(response);
            }).catch(error => {
            console.log(error);
        })
    };

    const [refreshed, setRefreshed] = useState(0);
    const handleSetRefreshed = () => {
        setRefreshed(refreshed + 1);
    }

    const [logoutOpen, setLogoutOpen] = useState(false);

    const handleLogoutOpen = () => {
        setLogoutOpen(true);
    }

    const handleLogoutClose = () => {
        setLogoutOpen(false);
        history("/welcome")
    }

    const handleLogout = () => {
        removeCookie("jwt");
        localStorage.removeItem("ranch");
        localStorage.removeItem("ids");
        setIsLogged(false);
    };

    /*useEffect(() => {
        let inactivityTimer;

        const resetTimer = () => {
            clearTimeout(inactivityTimer);
            if (isLogged) {
                inactivityTimer = setTimeout(() => {
                    handleLogout();
                    console.log('User logged out due to inactivity');
                    handleLogoutOpen();
                }, 30 * 60 * 1000); // 30 minutes
            }
        };

        const handleUserActivity = () => {
            resetTimer();
        };

        // Set up event listeners for user activity
        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);

        // Initialize the timer
        resetTimer();

        // Clean up event listeners on component unmount
        return () => {
            clearTimeout(inactivityTimer);
            window.removeEventListener('mousemove', handleUserActivity);
            window.removeEventListener('keydown', handleUserActivity);
        };
    }, [isLogged]);


    useEffect(() => {
        let expirationTimer;

        const resetTimer = () => {
            clearTimeout(expirationTimer);
            if (isLogged) {
                expirationTimer = setTimeout(() => {
                    cookies.jwt == undefined ? setIsLogged(false) : setIsLogged(true);
                    console.log('User logged out due to token expiration');
                    history("/welcome");
                }, 3 * 60 * 60 * 1000);  //three hours
            }
        };

        const handleUserActivity = () => {
            resetTimer();
        };

        // Set up event listeners for user activity
        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);

        // Initialize the timer
        resetTimer();

        // Clean up event listeners on component unmount
        return () => {
            clearTimeout(expirationTimer);
            window.removeEventListener('mousemove', handleUserActivity);
            window.removeEventListener('keydown', handleUserActivity);
        };
    }, [isLogged]);*/

    useEffect(() => {
        if (isLogged) {
            axios.get('/api/Account', config)
                .then(async function (response) {
                    setUser(response.data);
                    setEmail(response.data.email);
                    setDarkMode(response.data.darkMode);
                    if(!response.data.hasLoggedOnce){
                        openNewPlayer();                        
                    }
                }).catch(async function (error) {
                console.log(error);
            });
        }
    }, [isLogged, refreshed]);

    const [rollover, setRollover] = useState(false);

    /*    useEffect(() => {
            if(cookies.jwt){
                axios.get('/api/Application/checkRollover', config)
                    .then(response => {
                        setRollover(response.data);
                    });
            }
        }, []);*/

    const [month, setMonth] = useState();

    useEffect(() => {
        if (token != "null" && token != null) {
            axios.get('/api/Application/month', config)
                .then(response => {
                    setMonth(response.data);
                });
        }
        axios.get("/api/Application/test", config)
            .then(response => {
                setIsTest(response.data)
            }).catch(error => {
            console.log(error);
        })
    }, []);

    const secret = 'S441t8@s3cr3tShh';
    const ranchIdCoded = localStorage.getItem("ranch");
    const ranchId = ranchIdCoded != undefined ? Base64.decode(ranchIdCoded) : "";

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const [tosOpen, setTosOpen] = useState(false);

    const handleTosOpen = () => {
        setTosOpen(true);
    }

    const handleTosClose = () => {
        setTosOpen(false);
    }

    const [privacyOpen, setPrivacyOpen] = useState(false);

    const handlePrivacyOpen = () => {
        setPrivacyOpen(true);
    }

    const handlePrivacyClose = () => {
        setPrivacyOpen(false);
    }

    const [rulesOpen, setRulesOpen] = useState(false);

    const handleRulesOpen = () => {
        setRulesOpen(true);
    }

    const handleRulesClose = () => {
        setRulesOpen(false);
    }

    const [contactUsOpen, setContactUsOpen] = useState(false);

    const handleContactUsOpen = () => {
        setContactUsOpen(true);
    }

    const handleContactUsClose = () => {
        setContactUsOpen(false);
    }

    const [messageIds, setMessageIds] = useState([]);

    const handleSetMessageIds = (ids) => {
        setMessageIds(ids);
    }
    
    const [chatIsOpen, setChatIsOpen] = useState(false);
    
    const openChat = () => {
        setChatIsOpen(true);
    }

    const closeChat = () => {
        setChatIsOpen(false);
    }
    
    return (
        <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
            <CssBaseline/>
            <CookiesProvider>
                <div className={"app-container"}>
                    {month && <NavBar history={history} month={month} secret={secret}
                                      username={user != null ? user.displayName : ""} ranchId={ranchId}
                                      isLogged={isLogged} darkMode={darkMode} toggleDarkMode={toggleDarkMode}
                                      handleLogout={handleLogout} config={config} api={api} token={token}
                                      removeCookie={removeCookie} refreshed={refreshed}/>}
                    <Routes>
                        <Route exact path={"/"} element={<Navigate to={'/welcome'}/>}/>
                        <Route path={"/welcome"}
                               element={<Welcome user={user} config={config} api={api} token={token}/>}/>
                        {/*redirect during rollover*/}
                        {/*{rollover &&  <Route path={"/"}><Redirect to={"/rollover"} /></Route>}*/}
                        {rollover && <Route path={"/rollover"} element={<Rollover/>}/>}

                        {/*<Route path={"/register"}
                               element={<RegisterForm history={history} config={config} formConfig={formConfig}
                                                      handleTosOpen={handleTosOpen}
                                                      handlePrivacyOpen={handlePrivacyOpen}/>}/>*/}
                        <Route path={"/account/verifyEmail/:token/:email"}
                               element={<Confirmation history={history} api={api} token={token}/>}/>
                        <Route path={"/login"}
                               element={<LoginForm history={history} config={config} setIsLogged={setIsLogged}
                                                   setCookie={setCookie}/>}/>
                        <Route exact path={"/forgotPassword"} element={<ForgotPassword config={config}/>}/>
                        <Route exact path={"/account/resetPassword/:token/:email"}
                               element={<ResetPassword config={config}/>}/>

                        <Route exact path={"/search"}
                               element={<Search config={config} api={api} token={token} darkMode={darkMode}/>}/>

                        {user && <Route path={"/account"}
                                        element={<AccountManagement user={user} handleSetUpdated={handleSetRefreshed}
                                                                    config={config} api={api} token={token}
                                                                    formConfig={formConfig} email={email} darkMode={darkMode}/>}/>}
                        {/*{email && <Route path={"/createRanch"} element={<RanchCreationForm email={email} history={history} config={config} formConfig={formConfig}/>}/>}*/}
                        {/*<Route path={"/testing"} element={<Testing ranchId={ranchId} config={config}/>}/>*/}

                        <Route path={"/studs"}
                               element={<Studs ranchId={ranchId} darkMode={darkMode} config={config} api={api}
                                               token={token} formConfig={formConfig}
                                               handleSetRefreshed={handleSetRefreshed}/>}/>
                        <Route exact path={"/shows"} element={<Shows config={config} api={api} token={token} darkMode={darkMode}/>}/>
                        <Route path={"/shows/:id"}
                               element={<Show ranchId={ranchId} config={config} formConfig={formConfig} api={api}
                                              token={token} handleSetRefreshed={handleSetRefreshed}/>}/>

                        <Route exact path={"/horses"}
                               element={<Horses ranchId={ranchId} darkMode={darkMode} config={config} api={api}/>}/>
                        <Route exact path={"/sales"}
                               element={<Sales email={email} ranchId={ranchId} darkMode={darkMode} config={config}
                                               formConfig={formConfig} api={api} token={token}
                                               handleSetRefreshed={handleSetRefreshed}/>}/>

                        {user ? <Route path={"/horses/:id"}
                                       element={<Horse admin={user.admin} user={user}
                                                       ranchId={ranchId}
                                                       darkMode={darkMode} config={config}
                                                       formConfig={formConfig} api={api} token={token} date={month}
                                                       isTest={isTest}
                                                       handleSetRefreshed={handleSetRefreshed}/>}/> :
                            <Route path={"/horses/:id"}
                                   element={<Horse admin={false} owner={"none"} email={email} ranchId={ranchId}
                                                   darkMode={darkMode} config={config}
                                                   formConfig={formConfig} api={api} token={token} date={month}
                                                   isTest={isTest}
                                                   handleSetRefreshed={handleSetRefreshed}/>}/>}
                        {user && <Route path={"/ranches/:id"}
                                        element={<Ranch user={user}
                                                        ranchId={ranchId}
                                                        config={config}
                                                        api={api}
                                                        formConfig={formConfig}
                                                        token={token}
                                                        email={email}
                                                        importCredits={user.importCredits}
                                                        date={month}
                                                        customImportCredits={user.customImportCredits}
                                                        handleSetRefreshed={handleSetRefreshed}
                                                        darkMode={darkMode}
                                        />}/>}
                        {user && <Route path={"/clubs"}
                                element={<Clubs id={user.id} formConfig={formConfig} config={config} api={api}
                                                token={token} darkMode={darkMode}/>}/>}
                        {user && <Route path={"/clubs/:id"}
                                element={<Club user={user} formConfig={formConfig} config={config} api={api} token={token}
                                               darkMode={darkMode}/>}/>}
                        <Route path={"/rankings"}
                               element={<Rankings formConfig={formConfig} config={config} api={api} token={token} darkMode={darkMode}/>}/>
                        <Route path='/wiki' element={() => {
                            window.location.href = 'https://wiki.legacyhorsegame.com';
                            return null;
                        }}/>

                        {user && <Route path={"/forum"}
                                        element={<Forum user={user} darkMode={darkMode}
                                                        formConfig={formConfig} config={config}
                                                        api={api} token={token}/>}/>}
                        {user && <Route path={"/forum/:id"}
                                        element={<SubCategory user={user} darkMode={darkMode}
                                                              config={config} formConfig={formConfig} api={api}
                                                              token={token} />}/>}
                        {user && <Route path={"/forum/posts/:id"}
                                        element={<Post user={user} darkMode={darkMode}
                                                         config={config} formConfig={formConfig} api={api}
                                                         token={token} history={history}/>}/>}

                        {user && <Route path={"/inbox"}
                                element={<Messages id={user.id} api={api} token={token} formConfig={formConfig}
                                                   config={config} handleSetMessageIds={handleSetMessageIds}/>}/>}
                        {user && <Route path={"/messages/:id"}
                                        element={<MessageThread id={user.id} messageIds={messageIds} api={api} token={token} formConfig={formConfig}
                                                           config={config} darkMode={darkMode} history={history}/>}/>}

                        {user && <Route path={"/users/:displayName"}
                                        element={<Landing user={user} secret={secret} email={email}
                                                          history={useNavigate} config={config} formConfig={formConfig}
                                                          api={api} token={token} darkMode={darkMode}/>}/>}
                        {/*<Route path={"/users/:displayName"} render={user => (<Landing user={user} secret={secret} email={email} history={useNavigate} config={config} formConfig={formConfig} api={api}/>)}/>*/}
                        {user && <Route exact path={"/home"} element={<Navigate to={`/users/${user.displayName}`}/>}/>}

                        <Route path="notfound" element={<NotFound/>}/>
                        <Route path="*" exact element={<NotFound/>}/>
                    </Routes>

                    {isLogged && <Chat sx={{
                        color: darkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main,
                        fontSize: "50px",
                        position: "fixed",
                        right: "20px",
                        bottom: "20px",
                        zIndex: 30
                    }} onClick={openChat}/>}

                    {chatIsOpen && <GlobalChat user={user} closeChat={closeChat} darkMode={darkMode}/>}
                    
                    <Footer className={"footer"} handleTosOpen={handleTosOpen} handlePrivacyOpen={handlePrivacyOpen}
                            handleContactUsOpen={handleContactUsOpen} handleRulesOpen={handleRulesOpen}/>

                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Login expired</DialogTitle>
                        <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                            Login expired, please log back in.
                        </DialogContentText>
                        <DialogActions>
                            <Button onClick={handleClose} variant={"contained"}>Close</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={tosOpen} onClose={handleTosClose}>
                        <DialogTitle>Terms of Service</DialogTitle>
                        <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                            <TermsOfService/>
                        </DialogContentText>
                        <DialogActions>
                            <Button onClick={handleTosClose} variant={"contained"}>Accept</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={privacyOpen} onClose={handlePrivacyClose}>
                        <DialogTitle>Privacy Policy</DialogTitle>
                        <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                            <PrivacyPolicy/>
                        </DialogContentText>
                        <DialogActions>
                            <Button onClick={handlePrivacyClose} variant={"contained"}>Accept</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={rulesOpen} onClose={handleRulesClose}>
                        <DialogTitle>Rules</DialogTitle>
                        <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                            <GameRules/>
                        </DialogContentText>
                        <DialogActions>
                            <Button onClick={handleRulesClose} variant={"contained"}>Accept</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={contactUsOpen} onClose={handleContactUsClose}>
                        <DialogTitle>Contact Us</DialogTitle>
                        <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                            <ContactUs close={handleContactUsClose} formConfig={formConfig}/>
                        </DialogContentText>
                    </Dialog>
                    <Dialog open={logoutOpen} onClose={handleLogoutClose}>
                        <DialogTitle>Account Time-Out</DialogTitle>
                        <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                            You have been logged out due to inactivity.
                        </DialogContentText>
                        <DialogActions>
                            <Button onClick={handleLogoutClose} variant={"contained"}>Close</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={newPlayerOpen} onClose={closeNewPlayer}>
                        <DialogTitle>Hello and welcome to Legacy!</DialogTitle>
                        <DialogContentText id="alert-dialog-description" sx={{paddingLeft: 4, paddingRight: 4}}>
                            <NewPlayerPopUp/>
                        </DialogContentText>
                        <DialogActions>
                            <Button onClick={closeNewPlayer} variant={"contained"}>Close</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </CookiesProvider>
        </ThemeProvider>
    )
}

export default App;

