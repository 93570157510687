import React from 'react';

import "./Introduction.css";
import {Typography} from "@mui/material";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import {lightTheme} from "../../Theme";

const Introduction = () => {
    
    const textStyle = {
        margin: "10px"
    }
    
    return (
        <div className={"intro-container"}>
            <Typography sx={{marginBottom: "20px"}} variant={"h2"}>What is Legacy?</Typography>
            <Typography sx={{marginBottom: "30px", color: lightTheme.palette.primary.main}} variant={"h3"}>A realistic horse breeding game.</Typography>
            <Typography sx={textStyle}>Legacy is a persistent, browser-based, realistic horse breeding game that aims to simulate the
                experience of owning and breeding competitive horses in real life.</Typography>
            <Typography sx={textStyle}>If you like genetics, or want to learn more about them, you’re in the right place! This game
                boasts the most complex and realistic genetics system of any horse game in existence. There’s a lot you
                can do here!
            </Typography>
            <Typography sx={textStyle}>The horses in the game have over 600 genes. These genes combine to create distinct individual
                horses, each with unique colors, personality, and abilities. The genetics of the game were designed by a
                professional biologist to mimic real life as closely as possible.</Typography>
            <Typography sx={textStyle}>Legacy is currently in closed alpha. We are in the process of transationing from alpha V1 to alpha V2.
            During this transition, new account registration will be closed.</Typography>{/*
            <Typography sx={textStyle}>Legacy is currently in open alpha. It is important to note that the game has and will have bugs
                but that we are committed to finding and fixing them as quickly as possible. If you are interested in
                joining the alpha, please feel free to sign up. Do take note, however, that the game will be wiped at
                the end of the alpha period.</Typography>*/}
            <Typography sx={textStyle}>For the most up-to-date news on the alpha version transition and progress, you can join the game's discord <Link className={"welcome-popup-link"}
                                                                                  to={"https://discord.gg/f2ZC4NyjuN"}>here</Link>.</Typography>
{/*            <Link style={{alignSelf: "center"}} to={"/register"}>
                <Button sx={{margin: "20px"}} variant={"contained"}>Register</Button>
            </Link>*/}
        </div>
    )
};

export default Introduction;